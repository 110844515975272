import { applyMiddleware, configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducers/RootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // 로컬 스토리지 사용

import thunk from "redux-thunk";
import { middleware } from "redux-pack";

// const store = createStore(RootReducer);
const middlewares = [thunk, middleware];

/**
 *
 * 휘발되지않는 데이터로 관리하려면 persist 라이브러리를 사용해야한다.
 *
 */
// Redux Persist 구성
const persistConfig = {
  key: "root",
  storage,
};
// rootReducer에 Redux Persist 적용
const persistedReducer = persistReducer(persistConfig, RootReducer);

export const CustomStore = configureStore({
  reducer: persistedReducer, // combineReducers로 리듀서를 묶은 리덕스 모듈 파일
  //   middleware: [ReduxThunk, logger], // 사용할 미들웨어들을 나열

  //redux-pack 미들웨어로 추가하기 ?
  middleware: middlewares, // 사용할 미들웨어들을 나열

  devTools: true, // 기본은 true로 설정되어있다. 개발자 도구의 사용 여부를 정한다.
  preloadedState: {
    // AnimeReducer: {
    //   list: [],
    // },
  }, // 리덕스 스토어가 생성될 때, 초기값을 정의한다.
});
export const persistor = persistStore(CustomStore);

// export default CustomStore;
