import { createTheme } from "@mui/material/styles";

export const primary = "#735a8a";
const MainTheme = createTheme({
  typography: {
    fontFamily: "'normal_kirakira'",
  },

  // root: {
  //   display: "flex",
  //   flexWrap: "wrap",
  //   "& > *": {
  //     boxShadow:
  //       "0px 3px 1px -2px red,0px 2px 2px 0px rgba(100,0,0,1),0px 1px 5px 0px rgba(0,0,0,0.12)",
  //   },
  // },

  palette: {
    // type: "light",

    primary: {
      // main: "#2e73a3",

      // main: "#8b60cc", // change

      // main: "#78755b",
      main: primary,

      contrastText: "#ffffff",
    },
    secondary: {
      // main: "#ead0da",
      main: "#e1c87c",
    },
    // point: {
    //   // main: "#ead0da",
    //   main: "#000000",
    // },
    background: {
      //이거 괜찮은듯.
      default: "#4f4659",

      paper: "rgba(252,252,230,0.90)",

      // paper: "rgba(234,233,223,0.93)", // change
      // paper: "rgba(221,237,255,0.90)", // change
    },
    text: {
      primary: "#474747", //change
      secondary: "#ae6795",
      disabled: "#545353",
      //   hint: "rgba(109,109,109,0.61)",
    },
    error: {
      main: "rgb(217, 51, 51)",
      // main: "rgb(245, 122, 0)",
      contrastText: "rgba(255,255,255,0.85)",
    },
    warning: {
      main: "rgb(255, 180, 20)",
      contrastText: "rgba(255,255,255,0.74)",
    },
    info: {
      main: "rgb(93, 192, 249)",
      contrastText: "rgba(255,255,255,0.74)",
    },
    success: {
      // main: "rgb(255, 139, 186)",
      main: "rgb(0, 216, 61)",
      contrastText: "rgba(255,255,255,0.74)",
    },
    divider: "rgba(193,26,247,0.1)",
  },
});

export const primaryBtn = "#b88fea";
export const lightGrey = "#f3f3f3";
export const lightBlueBg = "#e7f0f6";

export const lightYellow = "#ffffe6";

export const success = "rgb(0, 216, 61)";

export const spotlightBg = "#effeff";

export const primaryText = "#9b79b9";
export const primaryProductNode = "#b69ecb";

export const secondary = "#e1c87c";

export const greyBoxColor = "#979797";
export const greyBoxBorderColor = "#d6d6d6";

// export const orangeTextColor=
export const orangeBoxBgColor = "#ffdaa5";
export const orangePrimary = "#ff8b0f";
export const orangeSecondary = "#ffcb68";

export const yellowHighlightBg = "#feffc2";
export default MainTheme;
