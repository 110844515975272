// rootReducer.js
import { combineReducers } from "redux";
import createApiReducers from "../module/createReducers";

export const apiReducers = createApiReducers(
  "anime",
  "product",
  "order",
  "cart",
  "member",
  "delivery"
  //리듀서 추가
);
// const apiReducers = createApiReducers("product");

const RootReducer = combineReducers({
  // products: ProductReducer,

  // resList: AnimeReducer,

  // loading: loadingReducer,

  ...apiReducers,

  //   cart: cartReducer,
  //   user: userReducer,
});

export default RootReducer;
