import Link from "next/link";
import React, { Component } from "react";
import { secondary } from "@/styles/theme";

import divider from "@/res/drawable/divider.png";

class Footer extends Component {
  render() {
    return (
      !location.href.includes("/profile") == true &&
      !location.href.includes("/product/detail") == true &&
      !location.href.includes("/category/board") == true && (
        <div
          style={{
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          <img
            src={divider.src}
            style={{
              width: "80%",
              height: "auto",
              marginBottom: "50px",
            }}
          />
          <div
            style={{
              display: "block",
              // height: "100px",
              backgroundColor: "rgba(135, 104, 188, 0.7)",
              textAlign: "left",
              padding: "27px",
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                color: "white",
                fontSize: "11px",
                // size:"2",
                whiteSpace: "pre-line",
                fontFamily: "subTitle",
                lineHeight: "1.5",
              }}
            >
              <span style={{ color: secondary }}> 사업자 정보 : </span> 애니굿즈
              키라키라토모
              <br></br>
              <span style={{ color: secondary }}> 대표자 : </span>최성의
              <br></br>
              <span style={{ color: secondary }}> 주소 : </span>
              경기도 수원시 팔달구 향교로 4 건물 3층
              <br></br>
              <span style={{ color: secondary }}> 고객센터 : </span>
              050-6695-5912
              <br></br>
              <span style={{ color: secondary }}> 사업자 등록번호 : </span>
              571-12-01222
              <br></br>
              <span style={{ color: secondary }}> 통신판매업신고 :</span>{" "}
              2024-수원팔달-0281
              <br></br>
              <span style={{ color: secondary }}> 개인정보관리책임 : </span>
              최성의
              <br></br>
            </div>
          </div>
          <div
            style={{
              display: "inline-block",
              textAlign: "center",
              fontSize: "0.8rem",
            }}
            onClick={(e) => {
              location.href = "/siteRule";
            }}
          >
            <b>이용 약관 확인</b>
          </div>
          {" / "}
          <div
            style={{
              display: "inline-block",
              textAlign: "center",
              fontSize: "0.8rem",
            }}
            onClick={(e) => {
              location.href = "/refundRule";
            }}
          >
            <b>교환 환불 정책</b>
          </div>
          <div
            style={{
              padding: "15px",
              textAlign: "center",
              fontSize: "0.7rem",
            }}
          >
            「키라키라토모」에서는 개인정보를 암호화하여 안전하게 보관하고
            있습니다.
          </div>
        </div>
      )
    );
  }
}

export default Footer;
