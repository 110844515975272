import { handle } from "redux-pack";

import { DEFAULT_PAGE_SIZE } from "@/Utils/Constants";

export interface LoadingState {
  api_kirakira_list_FETCH_LIST: boolean;
  api_kirakira_list_FETCH: boolean;
  api_kirakira_list_CREATE: boolean;
  api_kirakira_list_UPDATE: boolean;
  api_kirakira_list_RESET: boolean;
  api_kirakira_list_DELETE: boolean;
}

export const FETCH_LIST = "api_kirakira_list_FETCH_LIST";
export const FETCH = "api_kirakira_list_FETCH";
export const CREATE = "api_kirakira_list_CREATE";
export const UPDATE = "api_kirakira_list_UPDATE";
export const RESET = "api_kirakira_list_RESET";
export const DELETE = "api_kirakira_list_DELETE";

export interface ErrorState {
  api_kirakira_list_FETCH_LIST: boolean;
  api_kirakira_list_FETCH: boolean;
  api_kirakira_list_CREATE: boolean;
  api_kirakira_list_UPDATE: boolean;
  api_kirakira_list_RESET: boolean;
  api_kirakira_list_DELETE: boolean;
}
export interface Pagination {
  number: number;
  size: number;
}

export interface ApiReducerState {
  //   ids: any[];
  //   entities: any;
  resData: any;
  qnaData: any;

  preorderList: any;
  loadingState: LoadingState;
  errorState: ErrorState;
  pagination: Pagination;

  orderCartData?: any;
  customerId?: number;
  selectedProduct?: any;
}

const createReducers = (...reduerNames: any[]) => {
  // const store = CustomStore != null? CustomStore : null;
  // const curState = store === null ? null : store.getState();

  return reduerNames.reduce((apiReducers: any, name: string) => {
    /**
     *
     * 초기 State 상태 정의
     *
     */
    const initState = {
      //   ids: [],
      //   entities: {},
      resData: [],
      qnaData: [],
      loadingState: {
        api_kirakira_list_FETCH_LIST: false,
        api_kirakira_list_FETCH: false,
        api_kirakira_list_CREATE: false,
        api_kirakira_list_UPDATE: false,
        api_kirakira_list_RESET: false,
        api_kirakira_list_DELETE: false,
      } as LoadingState,
      errorState: {
        api_kirakira_list_FETCH_LIST: false,
        api_kirakira_list_FETCH: false,
        api_kirakira_list_CREATE: false,
        api_kirakira_list_UPDATE: false,
        api_kirakira_list_RESET: false,
        api_kirakira_list_DELETE: false,
      } as ErrorState,
      pagination: {
        number: 0,
        size: DEFAULT_PAGE_SIZE,
      },
      preorderList: [],
      selectedProduct: {},
      orderCartData: [],
      customerKey: "",
      customerEmail: "",
      paymentPrice: 0,
      taxFreePrice: 0,
      isPreorder: true,
      customerName: "",

      deliveryAddr: {},
      /*
      isPopupOpen: false,
      
        컴포넌트 팝업: success 콜백 함수 내에서
         새로운 컴포넌트를 팝업으로 띄우고자 할 때는, 
         해당 팝업을 관리하는 상태 변수를 추가하고,
          success 콜백 함수에서 이 변수를 업데이트하여
           팝업을 렌더링하는 방식을 사용할 수 있습니다.
        */
    };
    apiReducers[name] = (state = initState, action: any) => {
      const { type, payload, meta, error, params } = action;

      //메타데이터에서 데이터 추출
      //resourceName, Key는 데이터 업데이트를 위해 사용 됌.
      const { /*key,*/ resourceName, notification } = meta || {};

      console.log("apiReducer: name: ", name);

      // console.log("apiReducer1: ", type);

      if (resourceName !== name) {
        return state;
      }
      console.log("apiReducer: ", type);

      switch (type) {
        case "ADD_ORDER_CART_DATA_cart": {
          console.log("ADD_ORDER_CART_DATA_cart: ", payload);
          return {
            ...state,
            orderCartData: payload,
          };
        }

        case "ADD_ORDER_DATA_cart": {
          console.log("ADD_ORDER_DATA_cart: ", payload);
          return {
            ...state,
            customerKey: action.customerKey,
            paymentPrice: action.paymentPrice,
            taxFreePrice: action.taxFreePrice,
            isPreorder: action.isPreorder,
            customerName: action.customerName,
            isCartOrderData: action.isCartOrderData,
          };
        }

        case "ADD_ORDER_DELIVERY_DATA_cart": {
          console.log("ADD_ORDER_DELIVERY_DATA_cart: ", payload);
          return {
            ...state,
            deliveryAddr: action.deliveryAddr,
          };
        }

        case UPDATE:
        case DELETE:
        case FETCH:
        case CREATE:
        case "FETCH_LIST_PREORDER_order":
        case FETCH_LIST: {
          return handle(state, action, {
            start: (prevState: any) =>
              start(prevState, type, action.params, resourceName),

            success: (prevState: any) => {
              /*
                리듀서 코드에서 success 시점에서 받는 payload는 
                주로 axios에서 반환된 response.data입니다. 
                response.data는 axios의 응답에서 
                실제 데이터를 포함하고 있는 부분입니다.
                */

              const { data } = payload;

              if (type === FETCH_LIST || type === "FETCH_LIST_PREORDER_order") {
                const { resData, pageNumber, pageSize, qnaData } = data;

                /*
data 배열을 순회하며, 각 요소의 key 값을 기준으로 객체 형태로 변환합니다. 
이렇게 변환된 객체는 entities 변수에 할당됩니다. 
여기서 key는 meta에서 전달된 값으로, 리소스의 고유 식별자를 나타냅니다.


pagenumber 가 0 이면, 최초 요청이므로,
기존 데이터를 모두 삭제하고, 새로운 데이터를 추가합니다.

pagenumber 가 0 이 아니면, 추가 요청이므로,
기존 데이터에 새로운 데이터를 추가합니다.
*/
                if (
                  !(resourceName === "product" || resourceName === "order") ||
                  ((resourceName === "product" || resourceName === "order") &&
                    pageNumber === 1)
                ) {
                  // const entities = data.reduce(
                  //   (finalEntities: any, entity: any) => ({
                  //     ...finalEntities,
                  //     [entity[key]]: entity,
                  //   }),
                  //   {}
                  // );

                  /**
                   * data 배열을 순회하며,
                   * 각 요소의 key 값을 추출하여 배열 형태로 저장합니다.
                   *  이렇게 생성된 ids 배열은 리소스의 식별자 목록을 나타냅니다.
                   *
                   */
                  // const ids = resData.map((entity: any) => entity[key]);

                  /**
                   *
                   * 위에서 추출한 ids,
                   *  entities, pageNumber, pageSize 등을
                   *  사용하여 상태를 업데이트합니다.
                   */

                  if (qnaData !== undefined) {
                    return {
                      ...prevState,
                      qnaData: qnaData,
                      loadingState: {
                        ...prevState.loadingState,
                        [`${type}`]: false,
                      },
                      errorState: {
                        ...prevState.errorState,
                        [`${type}`]: false,
                      },
                    };
                  } else {
                    return {
                      ...prevState,
                      resData: resData,
                      preorderList:
                        type === "FETCH_LIST_PREORDER_order"
                          ? resData
                          : prevState.preorderList,
                      //   ids,
                      //   entities: {
                      //     ...prevState.entities,
                      //     ...entities,
                      //   },
                      loadingState: {
                        ...prevState.loadingState,
                        [`${type}`]: false,
                      },
                      errorState: {
                        ...prevState.errorState,
                        [`${type}`]: false,
                      },
                      pagination: {
                        number: pageNumber,
                        size: pageSize,
                      },
                    };
                  }
                } else {
                  if (qnaData !== undefined) {
                    return {
                      ...prevState,
                      qnaData:
                        state.qnaData.length > 0
                          ? state.qnaData.concat(qnaData)
                          : qnaData,
                      loadingState: {
                        ...prevState.loadingState,
                        [`${type}`]: false,
                      },
                      errorState: {
                        ...prevState.errorState,
                        [`${type}`]: false,
                      },
                    };
                  } else {
                    console.log("resData: ", resData);
                    return {
                      ...prevState,
                      resData:
                        state.resData.length > 0
                          ? state.resData.concat(resData)
                          : resData,
                      preorderList:
                        type === "FETCH_LIST_PREORDER_order"
                          ? state.preorderList.concat(resData)
                          : state.preorderList,
                      loadingState: {
                        ...prevState.loadingState,
                        [`${type}`]: false,
                      },
                      errorState: {
                        ...prevState.errorState,
                        [`${type}`]: false,
                      },
                      pagination: {
                        number: pageNumber,
                        size: pageSize,
                      },
                    };
                  }
                }
              } else if (type === FETCH) {
                const { resData, qnaData } = data;

                return {
                  ...prevState,
                  resData: resData,
                  loadingState: {
                    ...prevState.loadingState,
                    [`${type}`]: false,
                  },
                  errorState: {
                    ...prevState.errorState,
                    [`${type}`]: false,
                  },
                };
              } else {
                if (type === DELETE) {
                  //qna의 경우 중복호출될수있으므로 수정하기. - name product을 사용하고있음.
                  alert(notification.success);
                }

                //새로고침
                window.location.reload();

                // const id = data[key];
                return {
                  ...prevState,
                  //   entities: {
                  //     ...prevState.entities,
                  //     [id]: data,
                  //   },
                  loadingState: {
                    ...prevState.loadingState,
                    [`${type}`]: false,
                  },
                  errorState: {
                    ...prevState.errorState,
                    [`${type}`]: false,
                  },
                };
              }
            },

            failure: (prevState: any) => failure(prevState, payload, type),
          });
        }

        case RESET:
          return initState;
        default:
          return state;
        case "ADD_PRODUCT_SELECTED_DATA_product":
          return handle(state, action, {
            start: (prevState: any) =>
              start(prevState, type, action.params, resourceName),
            success: (prevState: any) => {
              console.log("ADD_ORDER_CART_DATA_cart: ", payload);
              return {
                ...prevState,
                selectedProduct: payload.data.resData,
                loadingState: {
                  ...prevState.loadingState,
                  [`${type}`]: false,
                },
                errorState: {
                  ...prevState.errorState,
                  [`${type}`]: false,
                },
              };
            },
            failure: (prevState: any) => failure(prevState, payload, type),
          });

        // case "FETCH_LIST_PREORDER_order": {
        //   return handle(state, action, {
        //     start: (prevState: any) => start(prevState, type),
        //     success: (prevState: any) => {
        //       console.log("FETCH_LIST_PREORDER_order: ", payload);
        //       return {
        //         ...prevState,
        //         // preorderList: payload.data.resData,
        //         resData: payload.data.resData,
        //         loadingState: {
        //           ...prevState.loadingState,
        //           [`${type}`]: false,
        //         },
        //         errorState: {
        //           ...prevState.errorState,
        //           [`${type}`]: false,
        //         },
        //       };
        //     },
        //     failure: (prevState: any) => failure(prevState, payload, type),
        //   });
        // }
      }
    };

    return apiReducers;
  }, {});
};

const start = (
  prevState: any,
  type: string,
  params: any,
  resourceName: string
) => {
  if (prevState.loadingState[type] === true) {
    console.log("already loading");
    return prevState;
  } else {
    //최초 요청 시, loadingState를 true로 변경
    //페이징이면 최초요청시 이전 데이터 삭제. order,product

    if (
      (type.includes("FETCH_LIST") || type.includes("FETCH_")) &&
      (resourceName === "order" || resourceName === "product") &&
      params !== undefined &&
      params.pageNumber === 0
    ) {
      return {
        ...prevState,
        resData: [],
        qnaData: [],

        loadingState: {
          ...prevState.loadingState,
          [`${type}`]: true,
        },
        errorState: {
          ...prevState.loadingState,
          [`${type}`]: false,
        },
      };
    } else {
      return {
        ...prevState,
        loadingState: {
          ...prevState.loadingState,
          [`${type}`]: true,
        },
        errorState: {
          ...prevState.loadingState,
          [`${type}`]: false,
        },
      };
    }
  }
};
const failure = (prevState: any, payload: any, type: string) => {
  if (payload.response != undefined && payload.response.status === 401) {
    alert("로그인이 필요합니다.");
    window.location.href = "/login";
  }

  // const { errorMessage } = payload.reponse ? payload.response.data : null;
  //   {};

  return {
    ...prevState,
    loadingState: {
      ...prevState.loadingState,
      [`${type}`]: false,
    },
    errorState: {
      ...prevState.errorState,
      [`${type}`]:
        // errorMessage ||
        true,
    },
  };
};

export default createReducers;
